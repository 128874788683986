import React from "react";
import "../../../src/index.css"


export default function Giveaway_T_C() {
  return (
    <>
      <section id="OpenOpportunities" className="about-low-area section-padding2">
        <div className="container mt-0 mb-0 ml-22 mr-22" style={{paddingTop: "4%"}}>
          <section className="wantToWork-area team-area pb-160" id="volunteer">
            <div className="container pt-0 pb-0 pl-15 pr-15">
              <div className="col-md-12 pb-0 pl-15 pr-15">
                <div className="privacy-policy">
                  <div className="mt-5 mb-2" > 
                    <h2 style={{fontWeight: "500" ,color: "rgb(38, 204, 128)"}}>Official Facebook Giveaway Rules & Regulations</h2>
                  </div>
                  <br />
                  <h2>
                    NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES
                    NOT INCREASE THE CHANCES OF WINNING.
                  </h2>
                  <br />
                  <h2>1. Eligibility:</h2>
                  <p>
                    This Campaign is open only to those who are US citizens and
                    live in the State of Missouri.
                  </p>
                  <br />
                  <h2>2. Agreement to Rules:</h2>
                  <p>
                    By participating, the Contestant ("You") agree to be fully
                    unconditionally bound by these Rules, and You represent and
                    warrant that You meet the eligibility requirements. In
                    addition, You agree to accept the decisions of We Care STL,
                    Inc. as final and binding as it relates to the content of
                    this Campaign.
                  </p>
                  <br />
                  <h2>3. Campaign Period:</h2>
                  <p>
                    Entries will be accepted online starting 12/18/2020 at
                    10:30PM CST and ending 12/30/2020. All online entries must
                    be received by midnight, 12/30/2020.
                  </p>
                  <br />
                  <h2>4. How to Enter:</h2>
                  <p>
                    The Campaign must be entered by subscribing to our
                    newsletter at https://www.wecarestl.org/#subscribe and
                    either liking or commenting on our Facebook page or ad. The
                    entry must fulfill all Campaign requirements, as specified,
                    to be eligible to win a prize. Entries that are incomplete
                    or do not adhere to the rules or specifications may be
                    disqualified at the sole discretion of We Care STL, Inc.
                  </p>
                  <br />
                  <p>
                    You may enter only once. You must provide the information
                    requested. You may not enter more times than indicated by
                    using multiple email addresses, identities, or devices in an
                    attempt to circumvent the rules. If You use fraudulent
                    methods or otherwise attempt to circumvent the rules, your
                    submission may be removed from eligibility at the sole
                    discretion of We Care STL, Inc.
                  </p>
                  <br />
                  <h2>5. Prizes:</h2>
                  <p>
                    The Winner(s) of the Campaign (the "Winner") will receive a
                    customized We Care STL coffee mug, with a value of
                    approximately $10. Actual/appraised value may differ at time
                    of prize award. The specifics of the prize shall be solely
                    determined by We Care STL, Inc. No cash or other prize
                    substitution shall be permitted except at We Care STL's
                    discretion.
                  </p>
                  <br />
                  <p>
                    The prize is nontransferable. Any and all prize-related
                    expenses, including without limitation any and all federal,
                    state, and/or local taxes, shall be the sole responsibility
                    of Winner. No substitution of prize or transfer/assignment
                    of prize to others or request for the cash equivalent by
                    Winner is permitted. Acceptance of prize constitutes
                    permission for We Care STL to use Winner’s name, likeness,
                    and entry for purposes of advertising and trade without
                    further compensation, unless prohibited by law.
                  </p>
                  <br />
                  <h2>6. Odds</h2>
                  <p>
                    The odds of winning depend on the number of eligible entries
                    received.
                  </p>
                  <br />
                  <h2>7. Winner Selection and Notification:</h2>
                  <p>
                    Winner will be selected randomly under the supervision of We
                    Care STL personel. Winner will be notified by either email
                    or through our Facebook page within 24 hours following
                    selection of Winner. We Care STL shall have no liability for
                    Winner’s failure to receive notices due to spam, junk e-mail
                    or other security settings or for Winner’s provision of
                    incorrect or otherwise non-functioning contact information.
                  </p>
                  <br />
                  <p>
                    If Winner cannot be contacted, is ineligible, fails to claim
                    the prize within 30 days from the time award notification
                    was sent, or fails to timely return a completed and executed
                    declaration and release as required, the prize may be
                    forfeited and an alternate Winner selected. Receipt by
                    Winner of the prize offered in this Campaign is conditioned
                    upon compliance with any and all federal, state, and local
                    laws and regulations.
                  </p>
                  <br />
                  <p>
                    ANY VIOLATION OF THESE OFFICIAL RULES BY WINNER (at We Care
                    STL's sole discretion) WILL RESULT IN WINNER’S
                    DISQUALIFICATION AS WINNER OF THE CAMPAIGN, AND ALL
                    PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.
                  </p>
                  <br />
                  <h2>8. Rights Granted by You:</h2>
                  <p>
                    By entering this content (e.g., photo, video, text, etc.),
                    You understand and agree that We Care STL, anyone acting on
                    behalf of We Care STL, and We Care STL licensees,
                    successors, and assigns, shall have the right, where
                    permitted by law, to print, publish, broadcast, distribute,
                    and use in any media now known or hereafter developed, in
                    perpetuity and throughout the World, without limitation,
                    your entry, name, portrait, picture, voice, likeness, image,
                    statements about the Campaign, and biographical information
                    for news, publicity, information, trade, advertising, public
                    relations, and promotional purposes. without any further
                    compensation, notice, review, or consent.
                  </p>
                  <br />
                  <h2>9. Terms & Conditions:</h2>
                  <p>
                    We Care STL reserves the right, in its sole discretion, to
                    cancel, terminate, modify or suspend the Campaign should
                    virus, bug, non-authorized human intervention, fraud, or
                    other cause beyond We Care STL control corrupt or affect the
                    administration, security, fairness, or proper conduct of the
                    Campaign. In such case, We Care STL may select the Winner
                    from all eligible entries received prior to and/or after (if
                    appropriate) the action taken by We Care STL. We Care STL
                    reserves the right, in its sole discretion, to disqualify
                    any individual who tampers or attempts to tamper with the
                    entry process or the operation of the Campaign or website or
                    violates these Terms & Conditions. We Care STL has the
                    right, in its sole discretion, to maintain the integrity of
                    the Campaign, to void votes for any reason, including, but
                    not limited to: multiple entries from the same user from
                    different IP addresses; multiple entries from the same
                    computer in excess of that allowed by Campaign rules; or the
                    use of bots, macros, scripts, or other technical means for
                    entering. Any attempt by an entrant to deliberately damage
                    any website or undermine the legitimate operation of the
                    Campaign may be a violation of criminal and civil laws.
                    Should such attempt be made, We Care STL reserves the right
                    to seek damages to the fullest extent permitted by law.
                  </p>
                  <br />
                  <h2>10. Limitation of Liability:</h2>
                  <p>
                    By entering, You agree to release and hold harmless We Care
                    STL and its subsidiaries, affiliates, advertising and
                    promotion agencies, partners, representatives, agents,
                    successors, assigns, employees, officers, and directors from
                    any liability, illness, injury, death, loss, litigation,
                    claim, or damage that may occur, directly or indirectly,
                    whether caused by negligence or not, from: (i) such
                    entrant’s participation in the Campaign and/or his/her
                    acceptance, possession, use, or misuse of any prize or any
                    portion thereof; (ii) technical failures of any kind,
                    including but not limited to the malfunction of any
                    computer, cable, network, hardware, or software, or other
                    mechanical equipment; (iii) the unavailability or
                    inaccessibility of any transmissions, telephone, or Internet
                    service; (iv) unauthorized human intervention in any part of
                    the entry process or the Promotion; (v) electronic or human
                    error in the administration of the Promotion or the
                    processing of entries.
                  </p>
                  <br />
                  <h2>11. Disputes:</h2>
                  <p>
                    THIS Campaign IS GOVERNED BY THE LAWS OF MISSOURI WITHOUT
                    RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of
                    participating in this Campaign, participant agrees that any
                    and all disputes that cannot be resolved between the
                    parties, and causes of action arising out of or connected
                    with this Campaign, shall be resolved individually, without
                    resort to any form of class action, exclusively before a
                    court located in St. Louis County having jurisdiction.
                    Further, in any such dispute, under no circumstances shall
                    participant be permitted to obtain awards for, and hereby
                    waives all rights to, punitive, incidental, or consequential
                    damages, including reasonable attorney’s fees, other than
                    participant’s actual out-of-pocket expenses (i.e. costs
                    associated with entering this Campaign). Participant further
                    waives all rights to have damages multiplied or increased.
                  </p>
                  <br />
                  <h2>12. Privacy Policy:</h2>
                  <p>
                    Information submitted with an entry is subject to the
                    Privacy Policy stated on the We Care STL website.
                  </p>
                  <br />
                  <h2>13. Sponsor:</h2>
                  <p>The Sponsor of the Campaign is We Care STL.</p>
                  <br />
                  <p>
                    By participating in the Campaign, You, the Contestant, have
                    affirmatively reviewed, accepted, and agreed to all of the
                    Official Rules.
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
