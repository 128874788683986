import React from "react";
import companyLogo from "../../Assets/Images/we-care-stl-images/logos/circle-logo-img.png"
import {Link} from "react-router-dom"
import { faFacebook, faInstagram , faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Footer() {
  return (
    <>
      <footer className="footer section footer-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6" style={{marginRight:"15%"}}>
              <div className="widget">
                <h4 className="text-capitalize mb-4">Contact Info</h4>

                <ul className="list-unstyled footer-menu lh-35">
                  <li>
                    <a href="tel:+1-951-541-1792">
                      Phone number: +1-951-541-1792
                    </a>
                  </li>
                  <li style={{whiteSpace:"nowrap"}}>
                    <a href="mailto:wecarestlcontact@gmail.com">
                      Email: wecarestlcontact@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6" style={{marginRight:"18%"}}>
              <div className="widget">
                <h4 className="text-capitalize mb-4" style={{whiteSpace:"nowrap"}}>Important Links</h4>

                <ul className="list-unstyled footer-menu lh-35">
                 
                  
                  <li>
                    <Link to="/Privacy_Policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Terms_Of_Service">Terms of Service</Link>
                  </li>
                  
                  
                
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6 mx-auto">
              <div className="widget">
                <h4 className="text-capitalize mb-4">Newsletter</h4>
                <p>
                  Sign up to our newsletter for important updates on our
                  Fundraising campaigns and to see where your contributions go!
                </p>

                <form action="#" className="sub-form">
                  <input
                    type="email"
                    className="form-control mb-3"
                    placeholder="Email Address"
                    
                    
                  /> */}
                  {/* <a
                    href="#"
                    className="btn btn-main btn-small"
                    style={{color: "#fff"}}
                  >
                    SIGN UP
                  </a> */}

                  {/* <Link
                        style={{ backgroundColor: "rgb(38,204,128)", color: "#fff" }}
                        to="/Donation"
                        className="btn btn-main btn-lg header-btn mb-4 animated fadeInUp"
                        // className="btn btn-main btn-small  mb-4"
                        
                        >Sign up</Link> */}


                        {/* <div className="form-icon">
                          <button className="email-icon newsletter-submit button-contactForm" type="submit" id="newsletter-submit" name="submit" style={{height:"100%", backgroundColor:"#09CC7F", 
                            color:"white", padding:"8px 22px", display: "inline-block", textAlign:"center", position:"absolute"}}>
                            <i aria-hidden="true" className="fas fa-paper-plane"></i>
                          </button>

                        </div> */}
                {/* </form>
              </div>
              
            </div> */}

            <div className="col-lg-3 col-sm-6">
              <div className="widget">
                <div className="logo mb-4">
                  <img
                    src={companyLogo}
                    alt="We Care STL logo"
                    height="200"
                    width="200"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr style={{border: "0.1px solid #868c98"}} />

          <div className="footer-btm pt-4">
            <div className="row">
              <div className="col-lg-6" >
                <div className="copyright" style={{marginRight:"-100px"}}>
                  Copyright &copy; 2020 All rights reserved | This template is made with <i className="fa fa-heart" style={{color: "#26cc80"}}></i> 
                  {" "} by 
                  <a href="https://themefisher.com/"> Themefisher</a>
                  
                </div>
              </div>
              <div className="col-lg-6 text-left text-lg-right footer-top-socials" style={{marginLeft:"0px"}} >
                <ul className="list-inline footer-socials ">
                  <li className="list-inline-item">
                  <a
                  href="https://www.facebook.com/WeCareSTL"
                  aria-label="facebook"
                  target="_blank"
                  
                  >
                    <FontAwesomeIcon icon={faFacebook}/>
                  </a>
                  </li>
                  <li className="list-inline-item">
                  <a
                  href="https://www.linkedin.com/company/we-care-stl/"
                  aria-label="linkedin"
                  target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedin}/>
                  </a>
                  </li>
                  <li className="list-inline-item">
                  <a
                  href="https://www.instagram.com/wecarestl/"
                  aria-label="instagram"
                  target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram}/>
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr style={{border:'0.1px solid #868c98'}} />
          <p>
            Tax Information: We Care STL, Inc. is registered as a 501(c)(3)
            non-profit organization. Contributions to We Care STL are
            tax-deductible to the extent permitted by law. We Care STL Inc.'s
            tax identification number is 85-3651295.
          </p>
        </div>
      </footer>

      {/* <!--Scroll to top--> */}
      <div id="scroll-to-top" className="scroll-to-top">
        <span className="icon fa fa-angle-up"></span>
      </div>

      {/* <!--  */}
    </>
  );
}
