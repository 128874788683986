
import React from 'react'
import Navbar from '../Navbar/Navbar'
import Container from "react-bootstrap/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram , faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"


export default function Header() {
  return (
    <>
    
        <header className="navigation">
      <div className="header-top">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-2 col-md-4">
              <div
                className="header-top-socials text-center text-lg-left text-md-left"
              >
                <a
                  href="https://www.facebook.com/WeCareSTL"
                  aria-label="facebook"
                  target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook}/>
                  </a>
                <a
                  href="https://www.linkedin.com/company/we-care-stl/"
                  aria-label="linkedin"
                  target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedin}/>
                  </a>
                <a
                  href="https://www.instagram.com/wecarestl/"
                  aria-label="instagram"
                  target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram}/>
                  </a>
              </div>
            </div>
            <div
              className="col-lg-10 col-md-8 text-center text-lg-right text-md-right"
            >
              <div className="header-top-info mb-2 mb-md-0 header-top-socials">
                <a href="mailto:wecarestlcontact@gmail.com"
                  ><FontAwesomeIcon icon={faEnvelope} style={{marginRight:".5%"}}/><span>wecarestlcontact@gmail.com</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--------------------------------------------------NAVBAR-------------------------------------------------------------> */}
      <Navbar/>
    </header>
    
    </>
  )
}
