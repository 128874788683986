import React from "react";

export default function Privacy_Policy() {
  return (
    <>
      <section id="OpenOpportunities" class="about-low-area section-padding2">
        <div class="container mt-0 mb-0 ml-22 mr-22" style={{paddingTop: "4%"}}>
          <section class="wantToWork-area team-area pb-160" id="volunteer">
            <div class="container pt-0 pb-0 pl-15 pr-15">
              <div class="col-md-12 pb-0 pl-15 pr-15">
                <div class="privacy-policy">
                  <div class="mt-5 mb-2">
                    <h2 style={{fontWeight: "500" ,color: "rgb(38, 204, 128)"}}>Our Privacy Policy</h2>
                  </div>
                  <h2>Our Commitment to Privacy</h2>
                  <p>
                    We Care STL respects the privacy of every visitor to our
                    websites. Your privacy is a priority, and we go to great
                    lengths to protect it. This Privacy Policy describes how we
                    collect and use information about our online visitors. We
                    know you care about your information and we want you to be
                    fully informed about our practices. This Policy may be
                    changed or updated from time to time. By visiting the We
                    Care STL at one of our websites, you accept the practices
                    described in this Policy.
                  </p>

                  <h2>The Data We Collect</h2>
                  <p>
                    Our websites and mobile apps provide convenient ways for you
                    to access our services and learn about important We Care STL
                    opportunities and events. We collect information about our
                    online visitors both directly from our visitors and
                    indirectly from other companies that provide data to us. We
                    use this information to communicate with our supporters and
                    clients and process their transactions. We do not sell it to
                    other organizations.
                  </p>
                  <br />
                  <p>
                    When you use our websites or apps to make a financial
                    contribution, register for a class or special event, sign up
                    to volunteer, apply for a job, we may ask you for your name,
                    address, phone number(s), and other information relevant to
                    the transaction. We will ask for standard credit card
                    information when you make a financial donation, pay for a
                    class, or make a purchase.
                  </p>
                  <br />
                  <p>
                    We obtain information about our online visitors from other
                    sources as well. For example, we engage vendors that provide
                    contact information and other data. We use this data to help
                    us engage with people who may be interested in participating
                    in We Care STL activities or contributing to the We Care STL
                    mission. We also collect non-personal information about our
                    online visitors using “cookies”, pixels, web beacons, and
                    other technologies that operate automatically when you visit
                    our website.
                  </p>
                  <br />
                  <p>
                    We use this information to give our website visitors a more
                    relevant online experience and to help us make website
                    improvements. The information helps us understand which
                    parts of our websites are the most popular, where our online
                    visitors are going, and how long they spend there. If you do
                    not want us to collect information using cookies, you can
                    disable this function in your browser. If you block cookies
                    from us, however, you may not be able to see personalized
                    content and our website may not function optimally.
                  </p>
                  <br />
                  <h2>How We Secure Data</h2>
                  <p>
                    We use a variety of security measures to protect your data.
                    We maintain physical, electronic and procedural safeguards
                    to help prevent unauthorized access to and improper use of
                    personally identifiable information. We utilize Paypal to
                    process donations and Paypal protects the security of credit
                    card transactions using a number of measures such as
                    encryption, access controls, network firewalls, and physical
                    security. These measures make it extremely difficult for
                    anyone to intercept the credit card information you send to
                    Paypal for our donations. In general, when we work with
                    other companies to process credit card transactions, those
                    companies also use encryption and other appropriate security
                    measures. No website or electronic data can ever be
                    completely secure, but we are always working to maintain
                    up-to-date and appropriate security mechanisms.
                  </p>
                  <br />
                  <h2>How We Use Your Information</h2>
                  <p>
                    If you provide We Care STL with personal information when
                    you visit our websites or use our mobile apps, if
                    applicable, we may use the information in our programs and
                    activities. We may enter your information into one of our
                    databases so we can contact you to obtain your input,
                    provide information about our programs and events, request
                    donations, or provide a more personalized online experience.
                    We Care STL does not sell, trade, or share our donors’
                    personally identifiable information with any other entity,
                    except as described below. We do not send mailings to our
                    donors on behalf of other organizations unless the donor or
                    individual has given us specific permission to do so. On
                    occasion, We Care STL may share your information with our
                    vendors and other partners that help us with our activities
                    and programs. For example, we may share information with
                    vendors that help us fulfill orders, manage data, and
                    process donations and credit card payments.We also disclose
                    information as required by law.
                  </p>
                  <br />
                  <h2>We Care About Doing it Right</h2>
                  <p>
                    We Care STL is committed to accuracy and precision in
                    processing your information. We have procedures to check for
                    errors, and our systems are periodically audited to make
                    sure we process your information securely and accurately. If
                    you need to contact us to review your information or make
                    corrections, please contact us below.
                  </p>
                  <br />
                  <h2>Note to Parents About Our Privacy Policy</h2>
                  <p>
                    We Care STL has a variety of programs for children. We hope
                    that children and parents will visit our websites and use
                    our mobile apps together, so they can jointly learn about We
                    Care STL and opportunities for children to participate. We
                    Care STL does not intentionally collect personal information
                    from people under age 13.
                  </p>
                  <br />
                  <h2>Contact Us About Privacy</h2>
                  <p>
                    If you have questions about this Privacy Policy, you may
                    send us an email at contact@wecarestl.org or write to:
                    <br />
                    We Care STL 12847 Daylight Dr, 1215
                    <br />
                    St. Louis MO 63131
                    <br />
                    Attention: Privacy Officer
                    <br />
                  </p>
                  <br />

                  <br />
                  <p>Revised May 2021</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
