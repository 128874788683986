import React from "react";

export default function Terms_Of_Service() {
  return (
    <>
      <section id="OpenOpportunities" class="about-low-area section-padding2">
        <div
          class="container mt-0 mb-0 ml-22 mr-22"
          style={{ paddingTop: "4%" }}
        >
          <section class="wantToWork-area team-area pb-160" id="volunteer">
            <div class="container pt-0 pb-0 pl-15 pr-15">
              <div class="col-md-12 pb-0 pl-15 pr-15">
                <div class="privacy-policy">
                  <div class="mt-5 mb-2">
                    <h2 style={{fontWeight: "500" ,color: "rgb(38, 204, 128)"}}>Terms Of Service</h2>
                  </div>
                  <h2>Our Terms</h2>
                  <p>
                    This Terms of Service Agreement (these “Terms of Service) is
                    a binding legal contract between you (“You” or “Your” either
                    an individual or a legal entity) and We Care STL, Inc. (“We
                    Care STL”). These Terms of Service govern Your access to and
                    use of the web sites, media, apps, in-app services and any
                    other cloud services offered by We Care STL including
                    without limitation www.WeCareSTL.org web sites and any
                    media, software, programs, services, tools, features,
                    databases, materials, content, features, products, apps,
                    in-app services, or information available or sold on or
                    through it or used in connection with it (collectively, the
                    “Services” and each a “Service”). By downloading, accessing
                    or using any part of the Service You have accessed, You will
                    be bound by these Terms of Service. If You do not agree to
                    these Terms of Service, We Care STL is not willing to grant
                    You any right to use or access the Service. In such event,
                    You are not granted permission to access or otherwise use
                    the Service and are instructed to exit the Service
                    immediately.
                  </p>

                  <br />
                  <p>
                    We Care STL may change, add or remove portions of the Terms
                    of Service from time to time, and such changes shall become
                    effective immediately upon posting. It is Your
                    responsibility to review the Terms of Service prior to each
                    use of the Service, and by continuing to use the Service,
                    You agree to any changes, additions and removals.
                  </p>

                  <h2>Service Access And Use License</h2>

                  <p>
                    The Service You have accessed and all of its contents as a
                    whole and in part are protected by copyright, trademark,
                    service mark, trade name and other intellectual property,
                    and are owned or controlled by We Care STL, its affiliates
                    and licensors. The Service is licensed to You, not sold.
                    Except for the limited license granted in these Terms of
                    Service, We Care STL and its licensors retain all right,
                    title and interest in the Service and all proprietary rights
                    in the Service, including without limitation copyrights,
                    patents, trademarks, service marks and trade names.
                  </p>

                  <br />
                  <p>
                    We Care STL may change, suspend, or discontinue any aspect
                    of the Service at any time. We Care STL, its suppliers and
                    service providers reserve all rights not granted in these
                    Terms of Service.
                  </p>

                  <br />
                  <p>
                    1. Grant of License. We Care STL grants You a personal,
                    revocable, nontransferable, nonexclusive license to use the
                    Service for personal, non-commercial purposes, conditioned
                    on Your continued compliance with these Terms of Service.
                  </p>

                  <br />
                  <p>
                    2. LIMITATIONS ON LICENSE. The license granted to You in
                    these Terms of Service is restricted as follows:
                  </p>

                  <br />
                  <p>
                    a) Limitations on Copying and Distribution. You may download
                    or copy downloadable items displayed on the Service for
                    personal, non-commercial use only, provided that You
                    maintain and display all copyright, trademark and other
                    notices contained therein.
                    <br />
                    b) Limitations on Use. You may use and access the Service
                    solely for Your personal, non-commercial use. All use of the
                    Service must be in accordance with these Terms of Use. You
                    may not use, copy, store, reproduce, transmit, distribute,
                    display, modify, alter, license, sublicense, or commercially
                    exploit the Service or any contents, information, data or
                    materials provided through the Service in any manner not
                    expressly permitted by these Terms of Use or the Service.
                    You may not assign, sublicense, rent, timeshare, loan, lease
                    or otherwise transfer the Service, or directly or indirectly
                    permit any third party to use or copy any of the Services.
                    You may not: (i) use any “deep link”, “page scrape”,
                    “robot”, “spider”, or other automatic device, program,
                    script, algorithm or methodology, or any similar or
                    equivalent manual process, to access, acquire, copy, or
                    monitor any portion of the Service or in any way reproduce
                    or circumvent the navigational structure or presentation of
                    the Service to obtain or attempt to obtain any materials,
                    documents, or information through any means not purposefully
                    made available through the Service; (ii) attempt to gain
                    unauthorized access to any portion or feature of the
                    Service, including without limitation, the account of any
                    other authorized user or any other systems or networks
                    connected to the Service or to any server used by We Care
                    STL or to any of the services offered on or through the
                    Service, by hacking, password “mining” or any other
                    illegitimate or prohibited means; (iii) probe, scan, or test
                    the vulnerability of the Service or any network connected to
                    the Service, or breach the security or authentication
                    measures on the Service, or any network connected to the
                    Service; (iv) reverse look-up, trace, or seek to trace any
                    information on any other authorized user of or visitor to
                    the Service; (v) take any action that imposes an
                    unreasonable or disproportionately large load on the
                    infrastructure of the Service or systems or networks of We
                    Care STL or networks connected to the Service; (vi) use any
                    device, software, or routine to interfere with the proper
                    working of the Service or any transaction being conducted on
                    the Service, or with any other person’s use of the Service;
                    (vii) use the Service to harvest or collect e-mail addresses
                    or other contact information; (viii) market, co-brand,
                    private label, separately distribute, resell or otherwise
                    permit third parties to access and use the Service without
                    the express, separate and prior written permission of We
                    Care STL; (ix) create an intact reproduction of a page or
                    pages of the Service into another service; or (x) use the
                    Service in an unlawful manner or in a manner that could
                    damage, disparage, or otherwise negatively impact We Care
                    STL.
                    <br />
                    c) Non-Solicitation. You may not post or transmit through
                    the Service any material which contains advertising or any
                    solicitation with respect to products or services.
                    <br />
                    d) Limitations on Reverse Engineering and Modification. You
                    may not reverse engineer, decompile, disassemble, modify or
                    create works derivative of the Service or any materials
                    accessed through the Service.
                    <br />
                    e) Proprietary Notices. You may not remove any proprietary
                    notices, including copyright and trademark notices, or
                    labels from the Service on any materials accessed through
                    the Service.
                    <br />
                    f) Compliance with Applicable Law. You are solely
                    responsible for ensuring Your use of the Service is in
                    compliance with all applicable foreign, federal, state and
                    local laws, rules and regulations.
                    <br />
                  </p>

                  <br />
                  <p>
                    3. USER OBLIGATIONS. By downloading, accessing, or using the
                    Service in order to view information and materials or submit
                    Material (as defined below) of any kind, You represent that
                    You: (i) are at least eighteen (18) years of age, or between
                    thirteen (13) and seventeen (17) years of age and using the
                    Service with parental or legal guardian consent and
                    supervision; (ii) are registered to the extent required, and
                    have not been previously restricted, suspended or terminated
                    by We Care STL; and (iii) are not using another authorized
                    user’s account without that person’s permission.
                  </p>

                  <br />
                  <p>
                    4. YOUR SUBMISSION OF CONTENT. The Service may contain
                    comment areas, questionnaires, tools, and other interactive
                    features where You can share and display content,
                    information, postings, materials, including without
                    limitation, statements, photographs, video and other images
                    (collectively “Materials”). You are solely responsible for
                    any Materials You provide. By submitting Materials to the
                    Service, You warrant that You have all necessary rights to
                    grant, and automatically grant to We Care STL and its
                    affiliates, a royalty-free, worldwide, perpetual,
                    irrevocable, non-exclusive and fully sublicensable right
                    (including any moral rights) and license to use, license,
                    reproduce, modify, adapt, publish, publicly display,
                    publicly perform, translate, and distribute such Materials
                    (in whole or in part) and/or to incorporate them in other
                    works in any form, media or technology now known or
                    developed in the future. You also permit any other users of
                    the Service to access, view, store or reproduce Your
                    Materials and content for that user’s personal,
                    non-commercial use. We Care STL will not be required to
                    treat any Materials as confidential, and You grant to We
                    Care STL the right to edit, copy, display, publish and
                    distribute any Materials made available via the Service by
                    You, without compensation to You or any other party
                    submitting the Materials for You. Notwithstanding the right
                    and license, by merely permitting Your Materials to appear
                    on the Service, We Care STL is not a publisher of such
                    Materials, and is merely functioning as an intermediary to
                    enable You to provide and display Materials. Moreover, and
                    except as provided in these Terms of Use with respect to the
                    right and ability of We Care STL to delete or remove
                    Materials, We Care STL does not endorse, oppose, or edit any
                    opinion provided by You or any other user and does not make
                    any representation with respect to, nor does it endorse the
                    accuracy, completeness, timeliness, or reliability of any
                    Materials displayed, uploaded, linked-to or distributed by
                    You or any other user. You agree that by using the Service,
                    You will not post or transmit any of the following:
                  </p>

                  <br />
                  <p>
                    a) anything that interferes with or disrupts the Service or
                    its operation;
                    <br />
                    b) Materials that are vulgar, obscene, indecent, profane, or
                    otherwise objectionable or offensive, defamatory, libelous,
                    unlawful, threatening, abusive, harassing, misleading, or
                    false;
                    <br />
                    c) unauthorized copyrighted materials, or any other material
                    that infringes on the intellectual property rights, rights
                    of publicity or personality, trade secrets, confidentiality,
                    or privacy of others;
                    <br />
                    d) Materials that encourage criminal conduct or that would
                    give rise to civil liability or otherwise violate any law or
                    regulation in any jurisdiction;
                    <br />
                    e) Materials that harm minors;
                    <br />
                    f) Materials that impersonate any other person or entity,
                    whether actual or fictitious, or that misrepresent Your
                    affiliation with any entity; or
                    <br />
                    g) files that contain malicious code, viruses, corrupted
                    files, or any other similar software or programs that may
                    damage the operation of another’s computer, network, or the
                    Service.
                    <br />
                  </p>

                  <br />
                  <p>
                    5. OTHER TERMS AND CONDITIONS. Additional notices or terms
                    and conditions may apply to receipt of services,
                    participation in a particular program, sweepstakes,
                    training, authorized user registration to other specific
                    portions or features of the Service, all of which are made a
                    part of these Terms of Use by this reference. If there is a
                    conflict between these Terms of Use and the terms posted for
                    or applicable to a specific portion of the Service, the
                    latter terms shall control with respect to Your use of that
                    portion of the Service.
                  </p>

                  <br />
                  <p>
                    6. LINKS TO OTHER SERVICES. We Care STL may provide links,
                    in its sole discretion, to other services. We Care STL is
                    not responsible for the availability of these outside
                    resources or their contents. We Care STL expressly disclaims
                    any responsibility for the content, materials, accuracy of
                    information and/or the quality of the products or services
                    provided by, available through, or advertised on these
                    third-party services, or the products or services provided
                    by any third party.
                  </p>

                  <br />
                  <p>
                    7. LINKS TO THIS SERVICE. You may link to the Service
                    provided that You do so in a way that indicates that the
                    link is direct to an We Care STL Service and is fair and not
                    misleading. You may not integrate or make use of all or part
                    of the Service in ways that would confuse or mislead
                    visitors as to the nature and origin of the Service’s
                    content.
                  </p>

                  <br />
                  <p>
                    8. PASSWORDS; SECURITY. Registration by You as an authorized
                    user for access to certain areas of the Service may require
                    both a user name and a password (“Authorized User
                    Credentials”). You may also have to provide certain
                    registration information, all of which must be accurate and
                    updated. You are not allowed to share or give Your
                    Authorized User Credentials to anyone else. You are
                    responsible for maintaining the confidentiality of Your
                    password. You agree not to use another person’s Authorized
                    User Credentials or select Authorized User Credentials: (i)
                    with the intent to impersonate another person; (ii) subject
                    to the rights of another person without authorization; (iii)
                    in violation of the intellectual property rights of another
                    person; or (iv) that We Care STL, in its sole discretion,
                    deems inappropriate or offensive or discriminates on the
                    basis of race, color, sex, language, religion, political or
                    other opinion, national or social origin, property, birth or
                    other status. You acknowledge and agree that You shall have
                    no ownership or other property interest in Your account
                    and/or user name, and You further acknowledge and agree that
                    all rights in and to Your user name and account are and
                    shall forever be owned by and inure to the benefit of We
                    Care STL. Failure to comply with the foregoing shall
                    constitute a breach of these Terms of Use, which may result
                    in immediate suspension or termination of Your Authorized
                    User Credentials. We Care STL WILL NOT BE LIABLE FOR ANY
                    LOSS OR DAMAGE ARISING FROM YOUR FAILURE TO COMPLY WITH
                    THESE OBLIGATIONS. We Care STL reserves the right to delete
                    or change Authorized User Credentials (with or without
                    notice) at any time and for any reason.
                  </p>

                  <br />
                  <p>
                    9. TERMINATION. These Terms of Use and Your license to use
                    the Service will take effect the moment You install, access,
                    or use the Service and will be in effect until terminated as
                    set forth below. This license will automatically terminate
                    in the event You breach any of these terms. Termination will
                    be effective without notice. You may terminate these Terms
                    of Use at any time by ceasing to use the Service. All
                    applicable provisions of these Terms of Use as identified
                    below will survive termination. Upon termination, You must
                    destroy all copies of any aspect of the Service in Your
                    possession. The following will survive termination of these
                    Terms of Use for any reason: (i) the General section below;
                    (ii) the provisions concerning rights in Your Materials that
                    You have granted to We Care STL; and (iii) We Care STL’
                    proprietary rights, indemnity, disclaimers of warranty,
                    limitation of liability, and governing law.
                  </p>

                  <br />
                  <p>
                    10. WARRANTY DISCLAIMER. YOU EXPRESSLY AGREE THAT USE OF THE
                    SERVICE IS AT YOUR SOLE RISK AND YOU ASSUME ALL RISK. THE
                    SERVICE AND ITS INFORMATION, CONTENT, MATERIALS, PRODUCTS,
                    SERVICES, AND USER CONTENT IS PROVIDED ON AN “AS AVAILABLE,”
                    “AS IS” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE
                    CARE STL AND ITS LICENSORS DISCLAIM ALL WARRANTIES WITH
                    RESPECT TO THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE
                    IMPLIED WARRANTIES OF NON-INFRINGEMENT, TITLE,
                    MERCHANTABILITY, QUIET ENJOYMENT, QUALITY OF INFORMATION,
                    FITNESS FOR A PARTICULAR PURPOSE, AND RESULTS THAT MAY BE
                    OBTAINED FROM USE OF THE SERVICE. WE CARE STL DOES NOT
                    ENDORSE, RECOMMEND, OR MAKE ANY WARRANTIES AS TO THE
                    CONTENT, INFORMATION, MATERIALS, USER MATERIALS, FEATURES,
                    SERVICES, PRODUCTS, OPINIONS, OR STATEMENTS AVAILABLE ON OR
                    THROUGH THE SERVICE OR THROUGH LINKS ON THE SERVICE. WE CARE
                    STL DOES NOT WARRANT THAT THE SERVICE WILL MEET YOUR
                    REQUIREMENTS, OR THAT THE OPERATION OF THE SERVICE WILL BE
                    UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE SERVICE
                    WILL BE CORRECTED. YOU SPECIFICALLY ACKNOWLEDGE THAT WE CARE
                    STL IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL
                    CONDUCT OF OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF
                    INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
                  </p>

                  <br />
                  <p>
                    11. OTHER DISCLAIMERS. CONTENT ON THE SERVICE IS NOT
                    INTENDED TO SUBSTITUTE FOR PROFESSIONAL ADVICE OF ANY KIND.
                    IN PARTICULAR, NOTHING CONTAINED ON THE SERVICE IS OR SHOULD
                    BE CONSIDERED, OR USED AS A SUBSTITUTE FOR, MEDICAL ADVICE,
                    DIAGNOSIS OR TREATMENT. ALWAYS SEEK THE ADVICE OF YOUR
                    PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY
                    QUESTIONS YOU MAY HAVE REGARDING PERSONAL HEALTH OR MEDICAL
                    CONDITIONS. NEVER DISREGARD OR AVOID PROFESSIONAL MEDICAL
                    ADVICE OR DELAY IN SEEKING IT BECAUSE OF SOMETHING YOU HAVE
                    READ, HEARD OR SEEN ON THE SERVICE. WE CARE STL DOES NOT
                    REPRESENT OR WARRANT THAT PRODUCT INFORMATION ON THE SERVICE
                    IS ACCURATE OR COMPLETE, AS INFORMATION MAY BE PROVIDED BY
                    PRODUCT MANUFACTURERS OR SUPPLIERS, AND ON OCCASION
                    MANUFACTURERS MAY MODIFY THEIR PRODUCTS AND UPDATE THEIR
                    LABELS. WE RECOMMEND THAT YOU DO NOT RELY SOLELY ON THE
                    INFORMATION PRESENTED ON THE SERVICE AND THAT YOU CONSULT
                    THE PRODUCT’S LABEL OR CONTACT THE MANUFACTURER DIRECTLY IF
                    YOU HAVE A SPECIFIC CONCERN OR QUESTION ABOUT A PRODUCT.
                  </p>

                  <br />
                  <p>
                    12. LIMITATION OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED
                    BY LAW, IN NO EVENT WILL WE CARE STL OR ITS
                    SUPPLIERS/LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                    ANY DIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR
                    INDIRECT DAMAGES (WHETHER IN CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), OR OTHERWISE), WHICH INCLUDE, WITHOUT
                    LIMITATION, DAMAGES FOR PERSONAL INJURY, LOST PROFITS, LOST
                    DATA AND BUSINESS INTERRUPTION, ARISING OUT OF THE USE OF,
                    OR INABILITY TO USE, THE SERVICE, ITS CONTENT OR PRODUCTS,
                    OR ANY OF ITS THIRD PARTY CONTENT OR PRODUCTS AVAILABLE OR
                    SOLD ON OR THROUGH THE SERVICE, EVEN IF WE CARE STL HAS BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, THE
                    ENTIRE LIABILITY OF WE CARE STL AND ITS SUPPLIERS/LICENSORS
                    UNDER THESE TERMS OF USE FOR ALL DAMAGES, LOSSES, AND CAUSES
                    OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
                    OR OTHERWISE) IS LIMITED TO THE AMOUNT PAID BY YOU, IF ANY,
                    FOR ACCESS TO THE SERVICE.
                  </p>

                  <br />
                  <p>
                    Some states do not allow the exclusion of incidental or
                    consequential damages, or the limitation on how long an
                    implied warranty lasts, so some of the above may not apply
                    to You.
                  </p>

                  <br />
                  <p>
                    13. ONLINE SHOPPING. All product and service descriptions
                    and depictions are approximate and are provided for
                    convenience purposes only. The inclusion of any products or
                    services on the Service does not imply or warrant that such
                    products or services will be available. In the event a
                    product or service available on or through the Service is
                    listed at an incorrect price or with incorrect information,
                    We Care STL will have the right to refuse or cancel any
                    orders placed for the product or service listed at the
                    incorrect price, whether due to a typographical error, an
                    error in information received from We Care STL' suppliers or
                    providers, or otherwise. Your receipt of an electronic or
                    other form of order confirmation neither signifies We Care
                    STL’ acceptance of Your order, nor constitutes confirmation
                    of We Care STL’ offer to sell. We Care STL reserves the
                    right at any time after receipt of Your order to accept or
                    decline Your order for any reason. We Care STL may charge
                    and withhold the applicable sales tax for orders, otherwise.
                    You are solely responsible for all sales or other taxes, on
                    orders shipped to You. If You wish to purchase any product
                    made available through the Service, You may be asked to
                    supply certain information relevant to such transaction
                    including, without limitation, Your credit card number, its
                    expiration date and security code, the billing address, and
                    any shipping information. YOU REPRESENT AND WARRANT THAT YOU
                    HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) OR OTHER
                    PAYMENT METHOD(S) UTILIZED IN CONNECTION WITH ANY
                    TRANSACTION. By submitting such credit card or payment
                    information, You grant to the We Care STL the right to
                    provide such information to third parties for purposes of
                    facilitating the completion of any transactions initiated by
                    You or on Your behalf. Verification of information may be
                    required prior to the acknowledgment or completion of any
                    such transaction. It is Your responsibility to ascertain and
                    obey all applicable local, state, federal, and international
                    laws (including minimum age requirements) in regard to the
                    receipt, possession, use, and sale of any product or service
                    purchased from the Service. By placing an order, You
                    represent that the products and services ordered will be
                    used only in a lawful manner pursuant to any additional
                    terms that may accompany them. Except as otherwise set forth
                    herein, the risk of loss for and title to products or
                    services purchased on the Service passes to You upon
                    electronic transmission to the recipient or delivery to the
                    carrier, as applicable.
                  </p>

                  <br />
                  <p>
                    ONLINE SHOPPING. All product and service descriptions and
                    depictions are approximate and are provided for convenience
                    purposes only. The inclusion of any products or services on
                    the Service does not imply or warrant that such products or
                    services will be available. In the event a product or
                    service available on or through the Service is listed at an
                    incorrect price or with incorrect information, We Care STL
                    will have the right to refuse or cancel any orders placed
                    for the product or service listed at the incorrect price,
                    whether due to a typographical error, an error in
                    information received from We Care STL' suppliers or
                    providers, or otherwise. Your receipt of an electronic or
                    other form of order confirmation neither signifies We Care
                    STL’ acceptance of Your order, nor constitutes confirmation
                    of We Care STL’ offer to sell. We Care STL reserves the
                    right at any time after receipt of Your order to accept or
                    decline Your order for any reason. We Care STL may charge
                    and withhold the applicable sales tax for orders, otherwise.
                    You are solely responsible for all sales or other taxes, on
                    orders shipped to You. If You wish to purchase any product
                    made available through the Service, You may be asked to
                    supply certain information relevant to such transaction
                    including, without limitation, Your credit card number, its
                    expiration date and security code, the billing address, and
                    any shipping information. YOU REPRESENT AND WARRANT THAT YOU
                    HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) OR OTHER
                    PAYMENT METHOD(S) UTILIZED IN CONNECTION WITH ANY
                    TRANSACTION. By submitting such credit card or payment
                    information, You grant to the We Care STL the right to
                    provide such information to third parties for purposes of
                    facilitating the completion of any transactions initiated by
                    You or on Your behalf. Verification of information may be
                    required prior to the acknowledgment or completion of any
                    such transaction. It is Your responsibility to ascertain and
                    obey all applicable local, state, federal, and international
                    laws (including minimum age requirements) in regard to the
                    receipt, possession, use, and sale of any product or service
                    purchased from the Service. By placing an order, You
                    represent that the products and services ordered will be
                    used only in a lawful manner pursuant to any additional
                    terms that may accompany them. Except as otherwise set forth
                    herein, the risk of loss for and title to products or
                    services purchased on the Service passes to You upon
                    electronic transmission to the recipient or delivery to the
                    carrier, as applicable. Business customers must submit a
                    Credit Approval and Purchase Order form. Customers who are
                    approved for credit extensions will receive an invoice
                    following order shipment. Invoices are produced four (4)
                    times monthly so there may be more than one (1) order on an
                    invoice for the same bill-to address and the same or
                    different ship-to addresses. We do not send invoices to
                    addresses outside the United States and its territories.
                  </p>

                  <br />
                  <p>
                    You may opt into Electronic Invoice Delivery for the
                    established account. You will provide a valid email address
                    on your Credit Approval and Purchase Order form. You will
                    receive a link in the email to a PDF copy of your invoice.
                    The link expires thirty (30) days from receipt of the email.
                    You confirm that you are authorized to opt in to electronic
                    invoice delivery of We Care STL Store invoice(s) on behalf
                    of You or Your organization to one (1) designated email
                    address. You understand that You or Your organization will
                    not receive an invoice via postal mail after You have been
                    successfully enrolled in electronic delivery. If the billing
                    contact or invoice delivery email address changes for Your
                    account, You will update that information by contacting
                    Billing Support at contact@wecarestl.org. You or Your
                    organization is responsible for payment regardless of
                    receipt of the electronic invoice. If invoices are not
                    received as expected, You understand that it is Your
                    responsibility to contact Billing Support.
                  </p>

                  <br />
                  <p>
                    Standard Payment Terms are NET 30. Payment in full is due on
                    the date stated on the invoice. Payment of invoice is
                    accepted by credit card or check. Past due amounts shall be
                    subject to collections actions and may be referred to an
                    external collections agency which will result in the
                    termination of Your invoicing privileges. Credit card
                    payment will be required if invoicing privileges are
                    suspended or terminated.
                  </p>

                  <br />
                  <p>
                    14. DIGITAL MILLENNIUM COPYRIGHT ACT (“DMCA”) NOTICE. We
                    Care STL is committed to complying with copyright and
                    related laws, and requires all users of the Service to
                    comply with these laws. Accordingly, You may not store any
                    material or content or use or disseminate any material or
                    content though the Service in any manner that constitutes an
                    infringement of third party intellectual property rights,
                    including rights granted by copyright law. Owners of
                    copyrighted works who believe that their rights under
                    copyright law have been infringed may take advantage of
                    certain provisions of the Digital Millennium Copyright Act
                    of 1998 (the “DMCA”) to report alleged infringements. You
                    may not post, modify, distribute, or reproduce in any way
                    any copyrighted material, trademarks, or other proprietary
                    information belonging to others without obtaining the prior
                    written consent of the owner of such proprietary rights. It
                    is the policy of We Care STL to terminate use privileges of
                    any user who repeatedly infringes the copyright rights of
                    others upon receipt of proper notification to We Care STL by
                    the copyright owner or the copyright owner’s legal agent. If
                    You feel that a posted message is objectionable or
                    infringing, We Care STL encourages You to contact We Care
                    STL immediately. Upon We Care STL’ receipt of a proper
                    written notice of claimed infringement under the DMCA, We
                    Care STL will respond expeditiously to remove, or disable
                    access to, the material claimed to be infringing and will
                    follow all other relevant procedures specified in the DMCA
                    with regard to the claimed infringement. The designated
                    agent of We Care STL (i.e., the proper party) to whom You
                    should address such notice is listed below. If You believe
                    that Your work has been copied and posted using the Service
                    in a way that constitutes copyright infringement, please
                    provide We Care STL' designated agent with a written
                    communication containing the following information:
                  </p>

                  <br />
                  <p>
                    a) an electronic or physical signature of the person
                    authorized to act on behalf of the owner of the exclusive
                    right in the copyright that is allegedly infringed;
                    <br />
                    b) a description of the copyrighted work that You claim has
                    been infringed;
                    <br />
                    c) a description of where the material that You claim is
                    infringing is located to permit We Care STL to locate the
                    material;
                    <br />
                    d) Your address, telephone number, and email address;
                    <br />
                    e) a statement by You that You have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law; and
                    <br />
                    f) a statement by You, made under penalty of perjury, that
                    the information contained in Your report is accurate and
                    that You are the owner of the exclusive right in the
                    copyright that is allegedly infringed or authorized to act
                    on the owner's behalf.
                    <br />
                    g) We Care STL's designated agent for notice of claims of
                    copyright infringement can be reached as follows:
                    <br />
                  </p>

                  <br />
                  <p>
                    By E-Mail: contact@wecarestl.org with the subject line
                    “DMCA”
                  </p>

                  <br />
                  <p>
                    15. INTERNATIONAL USERS. While all Services are controlled
                    and operated within the United States, this publication may
                    be distributed internationally and may contain references to
                    We Care STL, and other We Care STL services, products, and
                    programs that are not in Your country. These references do
                    not imply that We Care STL, or other We Care STL services
                    intend to announce or provide the programs, products, or
                    services in Your country. All questions about We Care STL or
                    activities in Your country should be directed to the We Care
                    STL in Your country. We Care STL makes no representation
                    that content, materials or products available on or through
                    the Service are appropriate or available for use outside of
                    the United States. If You access or use the Service from a
                    location outside the United States, You are responsible for
                    compliance with applicable laws, including local laws
                    regarding online conduct and content and product use, local
                    national We Care STL society rules and policies, and U.S.
                    export laws and regulations.
                  </p>

                  <br />
                  <p>
                    16. MONITORING. We Care STL has the right, but not the
                    obligation, to monitor the content of the Service (including
                    any social or chat forum or tools, services or functions
                    that enable the sharing, display or performance of any
                    Material), to determine compliance with these Terms of Use
                    and any operating rules established by We Care STL, and to
                    satisfy any law, regulation or authorized government
                    request. We Care STL has the right and sole discretion to
                    edit, refuse to post, or to remove any Materials submitted
                    to or posted on or within the Service, including, without
                    limitation, the right to remove any Materials that We Care
                    STL, in its sole discretion, finds to be in violation of the
                    provisions hereof or otherwise objectionable.
                  </p>

                  <br />
                  <p>
                    17. INDEMNIFICATION. You agree to indemnify, defend and hold
                    We Care STL and its affiliates and its and their respective
                    governors, directors, officers, employees, volunteers, and
                    agents harmless from and against any and all claims,
                    actions, demands, damages, costs, liabilities, losses and
                    expenses (including reasonable attorneys’ fees and costs)
                    arising out of Your use of the Service.
                  </p>

                  <br />
                  <p>
                    18. PRIVACY POLICY. You understand, acknowledge, and agree
                    that the operation of certain programs, services, tools,
                    purchase options, materials, or information in the Service
                    requires the submission, use and dissemination of various
                    personal identifying information. Accordingly, if You wish
                    to access and use those programs, services, tools,
                    materials, purchase options, or information of the Service,
                    You acknowledge and agree that Your use of the Service will
                    constitute acceptance of the personal identifying
                    information collection and use practices contained in the
                    privacy policies posted on the Service.
                  </p>

                  <br />
                  <p>
                    19. TRADEMARK INFORMATION. The We Care STL emblem and
                    American We Care STL name and logo are federally protected
                    and registered marks of We Care STL and protected under
                    United States Code, Title 18, Section 706. All other
                    trademarks displayed on this Service or through links to
                    other services are the property of the respective trademark
                    owners.
                  </p>

                  <br />
                  <p>
                    20. GENERAL. These Terms of Use are governed by and
                    construed in accordance with the laws of the District of
                    Columbia, as applied to agreements entered into and wholly
                    performed within the District of Columbia between District
                    of Columbia residents. Any action or proceeding brought by
                    You or We Care STL shall be brought only in a court of
                    competent jurisdiction located in the District of Columbia
                    and You and We Care STL agree to submit to personal
                    jurisdiction of those courts for purposes of any action or
                    proceeding. These Terms of Use shall not be governed by the
                    1980 U.N. Convention on Contracts for the International Sale
                    of Goods. These Terms of Use constitute the entire
                    understanding and agreement between We Care STL and You with
                    respect to the Service and any transactions contemplated in
                    these Terms of Use, and supersede all prior or
                    contemporaneous oral or written communications with respect
                    to the subject matter of these Terms of Use, all of which
                    are merged into these Terms of Use. You may not modify,
                    amend or alter in any way these Terms of Use. In the event
                    any provision of these Terms of Use are found to be invalid
                    or unenforceable pursuant to judicial decree, the remainder
                    of these Terms of Use will remain valid and enforceable
                    according to its terms. Any failure by We Care STL to
                    strictly enforce any provision of these Terms of Use will
                    not operate as a waiver of that provision or any subsequent
                    breach of that provision. The disclaimers and limitations of
                    liability will survive any termination or expiration of
                    these Terms of Use. IT IS EXPRESSLY UNDERSTOOD AND AGREED
                    THAT IN THE EVENT ANY REMEDY HEREUNDER IS DETERMINED TO HAVE
                    FAILED ITS ESSENTIAL PURPOSE, ALL LIMITATIONS OF LIABILITY
                    AND EXCLUSIONS OF DAMAGES WILL REMAIN IN EFFECT.
                  </p>

                  <br />
                  <p>Revised May 2021</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
