

import Header from "../src/Layouts/Header/Header"
import About from "../src/Pages/About/About"
import Testimonials from "../src/Pages/Testimonials/Testimonials";
// import Volunteer from "../src/Pages/Volunteer/Volunteer";
import Team from "../src/Pages/Team/Team";
// import Contact from "../src/Pages/Contact/Contact"
import Giveaway_T_C from "../src/Pages/Giveaway_T_C/Giveaway_T_C"
import Donation from "../src/Pages/Donation/Donation"
import Privacy_Policy from "../src/Pages/Privacy-Policy/Privacy_Policy"
import Terms_Of_Service from "../src/Pages/Terms_Of_Service/Terms_Of_Service"
import  {Route, Routes}  from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css"
import Footer from "./Layouts/Footer/Footer";
import Container from "react-bootstrap/Container";
import BackToTopButton from "./BackToTopButton";
// import Important_Links from "./Pages/Important_Links/Important_Links";




function App() {
  return (
    <>
      <div className="container"  style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 , maxWidth: "100%" }}>
       
        <Header />
        
        <div className="container" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 0, marginLeft: 0 , maxWidth: "100%" }}>
              <Routes>
                  <Route exact path="/" element={<About />} />
                  <Route path="/About" element={<About />} />
                  <Route path="/Testimonials" element={<Testimonials />} />
                  {/* <Route path="/Volunteer" element={<Volunteer />} /> */}
                  <Route path="/Team" element={<Team />} />
                  {/* <Route path="/Contact" element={<Contact />} /> */}
                  {/* <Route path="/Important-Links" element={<Important_Links/>} /> */}
                  <Route path="/Donation" element={<Donation />} />
                  <Route path="/Giveaway_T_C" element={<Giveaway_T_C />} />
                  <Route path="/Privacy_Policy" element={<Privacy_Policy />} />
                  <Route path="/Terms_Of_Service" element={<Terms_Of_Service />} />

              </Routes>
            </div>
        <BackToTopButton/>
        <Footer/>
      </div>
    </>
  )
}

export default App;
