import React from "react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

function BackToTopButton() {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>

    {backToTopButton && (
        <div
        style={{
            cursor: "pointer",
            background: "#26cc80",
            position: "fixed",
            zIndex:"999",
            bottom:"30px",
            right:"30px",
            height:"40px",
            width:"40px",
            fontSize:"50px",
            borderRadius: "50%",
            textAlign: "center",

            lineHeight:"43px",
            transition:"0.3s"
        }}
          onClick={scrollUp}
        >
            <FontAwesomeIcon
           icon={faAngleUp}
            style={{
                height:"40px",
             color: "white",
             width: "40%"
            }}
           onClick={scrollUp}
         >
         
    //     </FontAwesomeIcon>

        </div>
    )}

    </div>

    // <div id="scroll-to-top" style={{ cursor: "pointer", display: "block" }}>
    //   {backToTopButton && (
        
    //     <button>
    //     <FontAwesomeIcon
    //       icon={faAngleUp}
    //        style={{
    //         color: "black"
    //        }}
    //       onClick={scrollUp}
    //     >
         
    //     </FontAwesomeIcon>
    //     </button>
    //   )}
    // </div>
  );
}

export default BackToTopButton;
