import React from "react";

// import "bootstrap/dist/css/bootstrap.min.css"
import Carousel from "./Carousel";
import { text } from "@fortawesome/fontawesome-svg-core";
export default function Testimonial_Cards() {
  return (
    <>
      {/* --------------------------------------------------------ORIGINAL ------------------------------------------------------------------ */}
      <section className="section testimonial">
        <div className="container" >
          <div className="row justify-content-center" style={{marginLeft:"0px"}} >
            <div className="col-lg-7 text-center" >
              <div className="section-title" >
                <span className="h6 text-color">Our Testimonials</span>
                <h2 className="mt-3 content-title">
                  Check what's our clients say about us
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ------------------------------------------------------------------------------------------------------------------- --> */}
        <div className="container" >
          <div className="row testimonial-wrap" style={{justifyContent:"center", textAlign:"center"}} >
             {/* --------------------------------------------------------------------- */}
            


            <Carousel/>


            {/* --------------------------------------------------------ORIGINAL ------------------------------------------------------------------ */}
            {/* <!------------------------------------------------------------------ --> */}
            {/* <!-- <div className="testimonial-item position-relative">
				<i className="ti-quote-left text-color"></i>

				<div className="testimonial-item-content">
					<p className="testimonial-text">Quam maiores perspiciatis temporibus odio reiciendis error alias debitis atque
						consequuntur natus iusto recusandae numquam corrupti.</p>

					<div className="testimonial-author">
						<h5 className="mb-0 text-capitalize">James Watson</h5>
						<p>Excutive Director,themefisher</p>
					</div>
				</div>
			</div>
			<div className="testimonial-item position-relative">
				<i className="ti-quote-left text-color"></i>

				<div className="testimonial-item-content">
					<p className="testimonial-text">Consectetur adipisicing elit. Quam maiores perspiciatis temporibus odio reiciendis
						error alias debitis atque consequuntur natus iusto recusandae .</p>

					<div className="testimonial-author">
						<h5 className="mb-0 text-capitalize">Mickel hussy</h5>
						<p>Excutive Director,themefisher</p>
					</div>
				</div>
			</div>  --> */}
            {/* <!------------------------------------------------------------------ --> */}
            {/* --------------------------------------------------------ORIGINAL ------------------------------------------------------------------ */}
          </div>
        </div>
      </section>
      {/* --------------------------------------------------------ORIGINAL ------------------------------------------------------------------ */}
      
      
    </>
  );
}
