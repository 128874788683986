import React from "react";
import Testimonial_Cards from "./Testimonial_Cards";
// import "bootstrap/dist/css/bootstrap.min.css"

export default function Testimonials() {
  return (
    <>
      <Testimonial_Cards/>
      {/* <!--  Section Services Start --> */}
      {/* <section class="section service  pb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 text-center">
              <div class="section-title">
                <span class="h6 text-color">Our Fundraising Campaigns</span>
                <h2 class="mt-3 content-title">
                  Explore The Latest Causes <br />
                  That We Worked Hard For
                </h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 fund-card">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src="images/we-care-stl-images/fundraiser-pics/DowntownSTL_arch.jpeg"
                    alt="Downtown St. Louis"
                    class="img-fluid w-100 team-img"
                  />
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">
                    January 2021 Downton St. Louis Medical Clinic
                  </h4>
                </div>
              </div>

              <div>
                <div id="countdown-wrap">
                  <div id="glass">
                    <div id="progress"></div>
                  </div>
                  <div>
                    <div class="goal-stat">
                      <span class="goal-number">$750</span>
                      <span class="goal-label">RAISED</span>
                    </div>
                    <div id="goals-wrap">
                      <div id="goal" class="align-content-end">
                        $750
                      </div>
                      <div id="goal-words" style={{marginRight: "20px"}}>
                        GOAL
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="col-lg-4 col-md-6 col-sm-6 fund-card">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src="images/we-care-stl-images/fundraiser-pics/SSMicon.jpeg"
                    alt="SSM Health"
                    class="img-fluid w-100 team-img"
                  />
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">
                    January 2022 SSM Health
                  </h4>
                </div>
              </div>

              <div>
                <div id="countdown-wrap" style={{marginTop: "75px;"}}>
                  <div id="glass">
                    <div id="progress-ssm"></div>
                  </div>
                  <div>
                    <div class="goal-stat">
                      <span class="goal-number">$0</span>
                      <span class="goal-label">RAISED</span>
                    </div>
                    <div id="goals-wrap">
                      <div id="goal" class="align-content-end">
                        $2,500
                      </div>
                      <div id="goal-words" style={{marginRight: "20px"}}>
                        GOAL
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--  Section Services End --> */}
    </>
  );
}
