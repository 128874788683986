import React from "react";
import IMG_401 from "../../Assets/Images/we-care-stl-images/team/IMG_401.png";
import IMG_402 from "../../Assets/Images/we-care-stl-images/team/IMG_402.png";
import IMG_403 from "../../Assets/Images/we-care-stl-images/team/IMG_403.png";
import IMG_404 from "../../Assets/Images/we-care-stl-images/team/IMG_404.png";
import IMG_405 from "../../Assets/Images/we-care-stl-images/team/IMG_405.png";
import IMG_406 from "../../Assets/Images/we-care-stl-images/team/IMG_406.png";
import IMG_407 from "../../Assets/Images/we-care-stl-images/team/IMG_407.png";
import IMG_1289 from "../../Assets/Images/we-care-stl-images/team/IMG_1289.png";

export default function Team() {
  return (
    <>
      {/* <!--  Section Services Start --> */}
      <section class="section team">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 text-center">
              <div class="section-title">
                <span class="h6 text-color">Who We Are</span>
                <h2 class="mt-3 content-title">
                  Our Passionate Leaders And Volunteer Are Always Ready
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_401}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Kiran Depala</h4>
                  <p>CEO and President</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_407}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Yesh Dhruva</h4>
                  <p>Vice President</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_402}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Neha Patil</h4>
                  <p>Public Relations</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_403}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Ziyan Zhang</h4>
                  <p>Outreach Chair</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5 mb-lg-0">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_404}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Madhura Puntambekar</h4>
                  <p>Secretary</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5 mb-lg-0">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_405}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Rohit Ponnada</h4>
                  <p>Care Package Coordinator</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5 mb-lg-0">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_406}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook" 
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true" ></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Lindsey Ferguson</h4>
                  <p>Treasurer</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="team-item-wrap mb-5 mb-lg-0">
                <div class="team-item position-relative">
                  <img
                    loading="lazy"
                    src={IMG_1289}
                    alt="team"
                    class="img-fluid w-100 team-img"
                  />
                  <div class="team-img-hover">
                    <ul class="team-social list-inline">
                      <li class="list-inline-item">
                        <a
                          href="https://www.facebook.com/WeCareSTL"
                          target="_blank"
                          class="facebook"
                        >
                          <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                      </li>

                      <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/we-care-stl/" target="_blank" class="linkedin">
                          <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="team-item-content">
                  <h4 class="mt-3 mb-0 text-capitalize">Mohsin Shaik</h4>
                  <p>Web Developer</p>
                </div>
              </div>
            </div>






          </div>
        </div>
      </section>
      {/* <!--  Section Services End --> */}
    </>
  );
}
