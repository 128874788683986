import React from 'react'

export default function Donation() {
  return (
    <> 
     <section id="OpenOpportunities" class="about-low-area section-padding2">
      <div class="container mt-0 mb-0 ml-22 mr-22" style={{paddingTop: "4%"}}>
        <section class="wantToWork-area team-area pb-160" id="volunteer">
          <div class="container pt-0 pb-0 pl-15 pr-15">
            <div class="col-md-12 pb-0 pl-15 pr-15">
            <div class="donations">
                <a href="#" class="btn btn-main btn-lg header-btn mb-4">Donate</a>
              <h2>
                You can impact the lives of people in our St. Louis community
              </h2>
              <p>
                Your generous donation makes our work possible. Thank you for supporting our programs. Please share this page with as many people as possible!
              </p>

              <div class="donation-options">
                <h2>$7</h2>
                
                <p>
                    This small donation is roughly the cost of a single care package. Each care package is a customized box with our logo on it and includes goodies such as a mini first aid kit, personal hand sanitizer, hand soap, multivitamins, cough drops, organic bags of tea and more.
                    </p>

                
              </div>

              <div class="donation-options">
                <h2>$20</h2>
                
                <p>
                    This helps us cover the cost of handing out 3 care packages in our community. Additionally, this would help us cover our domain and mail fees for about a year as we built out our website from scratch to keep costs to a minimum!
                </p>

                
              </div>
              <div class="donation-options">
                <h2>$50</h2>
                
                <p>
                    This helps us cover the cost of handing out 7 care packages in our community.
                </p>
              </div>

              <div class="donation-options">
                <h2>$100</h2>
                
                <p>
                    This helps us cover the cost of handing out about 15 care packages in our community.
                </p>
              </div>

             <div class="donation-options">
                <h2>$700</h2>
                
                <p>
                    This helps us cover the cost of handing out 100 care packages. We purchase supplies in bulk so once we have met our minimum Fundraiser threshold we purchase supplies and start putting our care packages together.
                </p>

              </div>
            </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    </>
  )
}
