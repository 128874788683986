
import React from 'react'
import  { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import TaniaProfilePic from "../../Assets/Images/we-care-stl-images/testimonial-pics/TaniaProfilePic.png"
import PlaceholderProfilePic from "../../Assets/Images/we-care-stl-images/testimonial-pics/placeholder-1.jpeg"
import Gateway180ProfilePic from "../../Assets/Images/we-care-stl-images/testimonial-pics/gateway180.jpeg"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function Carousel() {
  return (
    <>
    
    <Swiper
        spaceBetween={70}
        centeredSlides={true}
        autoplay={{
          delay: 12500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="testimonial-item position-relative" style={{ width:"50%"}}>
              <i className="ti-quote-left text-color"></i>
              <div className="testimonial-item-content"  >
                <div className="img-thumbnail .img-fluid w-50 h-50 testimonial-img" >
                  <img
                    className="w-100 testimonial-pic"
                    src={TaniaProfilePic}
                   
                  />
                </div>
                <p className="testimonial-text">
                  Each package is packed with everyday essentials that will
                  brighten anyone's day. On behalf of the Vivent Health team and
                  our clients, Thank You WE CARE STL!
                </p>

                <div className="testimonial-author">
                  <h5 className="mb-0 text-capitalize">TA'NIA JORDAN</h5>
                  <p>COORDINATOR, VIVENT HEALTH</p>
                </div>
              </div>
            </div>


        </SwiperSlide>
        <SwiperSlide> 
        <div className="testimonial-item position-relative" style={{ width:"50%"}} >
              <i className="ti-quote-left text-color"></i>
              <div className="testimonial-item-content"   >
                <div className="img-thumbnail .img-fluid w-50 h-50 testimonial-img" >
                  <img
                    className="w-100 testimonial-pic"
                    src={PlaceholderProfilePic}
                   
                  />
                </div>
                <p className="testimonial-text">
                The patients have shown big appreciation for the packages and
                  how useful the items are. Small gifts do mean a lot.
                </p>

                <div className="testimonial-author">
                  <h5 className="mb-0 text-capitalize">ANITA HOCKETT, RN</h5>
                  <p>CLINICAL DIRECTOR</p>
                </div>
              </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial-item position-relative" style={{ width:"50%"}}>
              <i className="ti-quote-left text-color"></i>
              <div className="testimonial-item-content"  >
                <div className="img-thumbnail .img-fluid w-50 h-50 testimonial-img" >
                  <img
                    className="w-100 testimonial-pic"
                    src={Gateway180ProfilePic}
                   
                  />
                </div>
                <p className="testimonial-text">
                  Thank you so much for your donations! The women and children really benefit from all of the items given. We are truly grateful.
                </p>

                <div className="testimonial-author">
                  <h5 className="mb-0 text-capitalize">GATEWAY 180 STAFF</h5>
                  <p>ST. LOUIS EMERGENCY FAMILY SHELTER</p>
                </div>
              </div>
            </div>
        </SwiperSlide>
        
      </Swiper>
    
    </>
  )
}
