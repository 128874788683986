import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faHandshakeAngle } from "@fortawesome/free-solid-svg-icons"
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import BackToTopButton from "../../BackToTopButton";
export default function About() {
  return (
    <>
      <Container
        fluid
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          marginRight: 0,
          marginLeft: 0,
        }}
      >
        {/* <!-- Slider Start --> */}
        <section class="slider">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-10">
                <div class="block">
                  <span class="d-block mb-3 text-white text-capitalize">
                    About Our Foundation
                  </span>

                  <h1 class="animated fadeInUp mb-5">
                    We Are On A Mission <br />
                    To Help Those In Need.
                  </h1>
                  <p style={{ color: "white" }}>
                    We Care STL is a nonprofit organization based in St. Louis,
                    Missouri that was founded in 2020 with a mission to support
                    the medically underinsured and underserved communities in
                    the area. They achieve this goal by building care packages
                    that include basic needs items such as cough drops, combs,
                    lotions, vitamins, teas, hand sanitizers, toothbrushes,
                    shampoos, soaps, toothbrushes and menstrual products. These
                    care packages are then distributed to those in need across
                    the community.
                  </p>
                  <p style={{ color: "white" }}>
                    We Care STL has partnered with several clinics in the St.
                    Louis area and has donated nearly 3000 care packages over
                    the past year to support individuals and families who are
                    struggling with limited access to healthcare and basic
                    necessities. Through their efforts, We Care STL is making a
                    positive impact on the lives of those in need and helping to
                    improve the overall health and wellbeing of the community.
                  </p>

                  {/* <Link
                    to="#"
                    className="btn btn-main animated fadeInUp"
                    style={{ color: "white" }}
                    aria-label="Get started"
                  >
                    Support Our Cause
                    <i class="btn-icon fa fa-angle-right ml-2"></i>
                  </Link> */}


                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section Intro Start --> */}
        {/* <!-- section Counter Start --> */}
        <section class="section counter bg-counter">
          <div class="container" style={{marginBottom:"-49px", marginTop:"-49px"}}>
            <div class="row" style={{justifyContent:"center"}}>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item text-center mb-5 mb-lg-0">
                  <i class=" color-one text-md"> </i>
                  <h3 class="mt-2 mb-0 text-white">
                    <span class="counter-stat font-weight-bold">
                      <CountUp start={0} end={200}/>
                    </span> +
                  </h3>
                  <p class="text-white">Volunteers</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item text-center mb-5 mb-lg-0">
                  <i class=" color-one text-md"></i>
                  <h3 class="mt-2 mb-0 text-white">
                    <span class="counter-stat font-weight-bold">$
                    <CountUp start={0} end={8000}/> </span>+
                  </h3>
                  <p class="text-white">Raised</p>
                </div>
              </div>
              
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="counter-item text-center">
                  <i class=" color-one  text-md"></i>
                  <h3 class="mt-2 mb-0 text-white">
                    <span class="counter-stat font-weight-bold">
                    <CountUp start={0} end={3000}/></span> +
                  </h3>
                  <p class="text-white">Carepackages Donated </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section Counter End  --> */}
        {/* <!-- In The News Start  --> */}
        <section class="section intro pb-5">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-title text-center">
                  <span class="h4 text-color">In The News </span>
                </div>
              </div>
            </div>
            <div class="row justify-content-center text">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="intro-item mb-5 mb-lg-0">
                  <div class="col-lg-2 col-md-2 col-sm-2 mr-5 ml-0">
                    <a
                      href="https://www.stlmag.com/health/we-care-stl-health-insurance/"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <div className="text-center,mb-50">
                        <span>
                          <img
                            src="images/we-care-stl-images/logos/stl-magazine.png"
                            alt="STL Magazine"
                            style={{ width: "300px", marginTop: "-50px", marginLeft: "15px" }}
                          />
                        </span>
                      </div>
                      <div class="cat-cap"></div>
                    </a>
                  </div>
                  {/* <!---------------------ST LOUIS MAGAZINE -------------------------------------> */}
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="intro-item mb-5 mb-lg-0">
                  <div class="col-lg-2 col-md-2 col-sm-2 mr-5 ml-0">
                    <a
                      href="https://www.stltoday.com/pr/news/we-care-stl-launches-as-a-platform-to-aid-uninsured-citizens-in-st-louis/article_9cbde36c-6265-11eb-b716-7376ebc2cc89.html"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <div className="text-center, mb-50">
                        <span>
                          <img
                            src="images/we-care-stl-images/logos/stl-today.png"
                            alt="STL Today"
                            style={{ width: "250px", marginTop: "-50px" , marginLeft: "30px"}}
                          />
                        </span>
                      </div>
                      <div class="cat-cap"></div>
                    </a>
                  </div>
                  {/* <!---------------------ST LOUIS TODAY---------------------------------------------> */}
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="intro-item">
                  <div class="col-lg-2 col-md-2 col-sm-2 mr-5 ml-0">
                    <a
                      href="https://marquettemessenger.com/mhsnews/2021/02/24/mhsnews-a-little-goes-a-long-way-with-we-care-stl/"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <div className={{ textCenter: "mb-50" }}>
                        <span>
                          <img
                            src="images/we-care-stl-images/logos/messenger-logo.png"
                            alt="The Messenger"
                            style={{ width: "300px", marginTop: "50px", marginLeft: "30px" }}
                          />
                        </span>
                      </div>
                      <div class="cat-cap"></div>
                    </a>
                  </div>
                  {/* <!---------------------THE MESSENGER-----------------------------------------------> */}
                </div>
              </div>
            </div>
          </div>
        </section>
         {/* <!-- In The News End  --> */}
        {/* <!-----------------------------------WHAT WE ARE DOING-------------------------------------------------> */}
        {/* <section class="section intro service-area">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="section-title" style={{ textAlign: "center" }}>
                  <span class="h4 text-color">What We Are Doing</span>
                  <h2 class="mt-3 content-title">
                    We Are On A Mission To Give Out As Many Care Package As We
                    Can
                  </h2>
                </div>
              </div>
            </div>
            <div class="row mt-5">
             
              <div class="col-lg-4 col-md-6 col-12">
                <div class="intro-item mb-5 mb-lg-0 text-center">
                  <i class="fas fa-pump-soap color-one"></i>
                  <h4 class="mt-4 mb-3">
                    Hand Sanitizers, Hand Soap and Wipes
                  </h4>
                  <p>
                    Each care package comes with a handy personal hand sanitizer
                    to use wherever you need, along with hand soap and hand
                    wipes.
                  </p>
                </div>
              </div>

              
              <div class="col-lg-4 col-md-6 col-12">
                <div class="intro-item mb-5 mb-lg-0 text-center">
                  <i class="fas fa-first-aid color-one"></i>
                  <h4 class="mt-4 mb-3">Mini First Aid Kit</h4>
                  <p>
                    We also provide mini First Aid Kits in each care package. Be
                    prepared for minor injuries and illness with our personal
                    First Aid Kit which include bandages and dressings which
                    come in a durable plastic case.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="intro-item mb-5 mb-lg-0 text-center">
                  <i class="fas fa-dot-circle color-one"></i>
                  <h4 class="mt-4 mb-3">
                    Stress Ball, Vitamins and Cough Drops
                  </h4>
                  <p>
                    Each care package comes with vitamin supplements, cough
                    drops and a stress ball with our customized logo. Keep
                    yourself healthy and happy and don't be stressed!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-----------------------------------WHAT WE ARE DOING-------------------------------------------------> */}
        
      </Container>
    </>
  );
}
