import React from "react";
import companyLogo from "../../Assets/Images/we-care-stl-images/logos/logo.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import ScrollLink from "react-scroll"



export default function Navbar(props) {
  let navItems = ["About", "Testimonials", "Team", "Important Links"];
  let navLinks = ["/About", "/Testimonials", "/Team",  "https://linktr.ee/wecarestl" ];
  let links = navItems.map((item, index) => {
    return (
      <li className="nav-item">
        <Link className="nav-link" to={navLinks[index]}>
          {item}
        </Link>
      </li>
    );
  });

  return (
    <>
      <Container fluid>
        <div id="navbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg px-0 py-4">
                  {/* <!--------------------------------------------------LOGO-------------------------------------------------------------> */}
                  <div className="brand-logo">
                    <Link className="navbar-brand" to={"/About"}>
                      <img
                        src={companyLogo}
                        alt="We Care STL logo"
                        height="150"
                        width="150"
                      />
                    </Link>
                  </div>

                  <Button
                    className="navbar-toggler collapsed"
                    style={{backgroundColor:"#26cc80", borderColor:"#25ad75"}}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExample09"
                    aria-controls="navbarsExample09"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars"></span>
                  </Button>

                  <div
                    className="collapse navbar-collapse text-center"
                    id="navbarsExample09"
                  >
                    <ul className="navbar-nav ml-auto">
                      {/* <li className="nav-item active @@about">
                        <a className="nav-link" href="index.html">About</a>
                        </li>
                        <li className="nav-item @@testimonials">
                        <a className="nav-link" href="testimonials.html"
                            >Testimonials</a
                        >
                        </li>
                        <li className="nav-item @@volunteer">
                        <a className="nav-link" href="volunteer.html">Volunteer</a>
                        </li>
                        <li className="nav-item @@team">
                        <a className="nav-link" href="team.html">Team</a>
                        </li>
                        <li className="nav-item @@contact">
                        <a className="nav-link" href="contact.html">Contact</a>
                        </li> */}

                      <li className="">
                        <Link className="active" to="/">
                          {props.children}
                        </Link>
                        
                      </li>
                      {links}
                    </ul>

                    <div className="my-2 my-md-0 ml-lg-4 text-center">
                      {/* <a href="contact.html" className="btn btn-main btn-lg header-btn" style={{backgroundColor:"rgb(38,204,128)"}}>Donate</a>  */}

                      {/* <Link
                        style={{ backgroundColor: "rgb(38,204,128)" }}
                        to="/Donation"
                        className="btn btn-lg  header-btn"
                      >
                        Donate
                      </Link> */}


                      <Link
                        to="https://www.paypal.com/donate?hosted_button_id=VPDJPV6L5V38A"
                        target="_blank"
                        className=" btn btn-main animated fadeInUp"
                        
                      >
                        Donate
                       <i class="btn-icon fa fa-angle-right ml-2"></i>
                        
                      </Link>


                      
                      




                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
// btn-main
